type Guid = string;

export enum FeedbackSource {
  Smiley = 'Smiley',
  OptOut = 'OptOut',
}

export enum FeedbackClient {
  Glammer = 'Glammer',
  Communities = 'Communities',
}

export type Rating = 1 | 2 | 3 | 4 | 5 | undefined;

export enum ManifestType {
  Sas = 'Sas',
}

export enum FeedbackType {
  Smile = 'Smile',
  Frown = 'Frown',
  Idea = 'Idea',
  Survey = 'Survey',
  Bug = 'Bug',
}

export interface AppData {
  readonly browserIdentity?: string;
  readonly forest?: string;
  readonly server?: string;
  readonly serverPageVersion?: string;
  readonly feedbackClient?: FeedbackClient;
  readonly feedbackSource: FeedbackSource;
}

export interface TelemetryData {
  readonly officeBuild?: string;
  readonly platform?: string;
  readonly osBuild?: string;
}

export interface WebData {
  readonly browser?: string;
  readonly browserVersion?: string;
  readonly dataCenter?: string;
}

export enum ComplianceCheckOption {
  NotConfigured = 'Not Configured',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export interface ComplianceChecks {
  readonly authenticationType: string;
  readonly ageGroup: string;
  readonly connectedExperiences: ComplianceCheckOption.Enabled | ComplianceCheckOption.Disabled | null;
  readonly policyAllowFeedback: ComplianceCheckOption;
  readonly policyAllowSurvey: ComplianceCheckOption;
  readonly policyAllowScreenshot: ComplianceCheckOption;
  readonly policyAllowContact: ComplianceCheckOption;
  readonly policyAllowContent: ComplianceCheckOption;
}

export interface UserInitiatedFeedback {
  readonly type: FeedbackType;
  readonly clientFeedbackId: Guid;
  readonly comment?: string;
  readonly email?: string;
  readonly rating: Rating;
  readonly appData: AppData;
  readonly telemetry: TelemetryData;
  readonly web: WebData;
  readonly complianceChecks: ComplianceChecks;
}
