import { LazyRelayModuleWrapper } from '@ms/yammer-relay/dist/environment';
import React, { FC } from 'react';

import { GlobalCopilotNavButton, GlobalCopilotNavButtonProps } from '../components/GlobalCopilot';

export const GlobalCopilotNavButtonIntegration: FC<GlobalCopilotNavButtonProps> = (props) => (
  <LazyRelayModuleWrapper>
    <GlobalCopilotNavButton {...props} />
  </LazyRelayModuleWrapper>
);
