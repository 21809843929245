import { useSelectedOnBehalfOfUserId } from '@ms/yammer-hooks/dist/analyticsSettings/selectors';
import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { useFeatureAccessInsights, useFeatureAccessInsightsStatus } from '@ms/yammer-hooks/dist/featureAccessInsights';

import { UseUIFeatureCheck } from '../types';

export {
  useIsCampaignsEntryPointVisible as useIsInsightsCampaignEnabled,
  useIsLeadersInsightsEntryPointVisible as useIsInsightsLeadersOrgNavigationEnabled,
  useIsMyInsightsEntryPointVisible as useIsInsightsLeadersMyInsightsNavigationEnabled,
  useIsQnAMyInsightsEntryPointVisible as useIsInsightsQnAMyInsightsEnabled,
  useIsQnAOrgEntryPointVisible as useIsInsightsQnAOrgEnabled,
} from '@ms/yammer-hooks/dist/insights';

const useInsightsLeadersPrerequisites: UseUIFeatureCheck<[], boolean | undefined> = () => {
  const vivaEngagePremiumExperiment = useExperimentTreatmentWithoutReporting('VivaEngagePremium');
  const featureAccessInsights = useFeatureAccessInsights();
  const featureAccessInsightsStatus = useFeatureAccessInsightsStatus();
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();

  if (vivaEngagePremiumExperiment !== true) {
    return false;
  }

  if (
    featureAccessInsightsStatus === 'pending' ||
    featureAccessInsights?.onBehalfOfUserId !== selectedOnBehalfOfUserId
  ) {
    return undefined;
  }

  return true;
};

export const useIsInsightsLeadersMyInsightsPageEnabled: UseUIFeatureCheck<[], boolean | undefined> = () => {
  const insightsLeadersPrerequisites = useInsightsLeadersPrerequisites();
  const featureAccessInsights = useFeatureAccessInsights();

  return insightsLeadersPrerequisites && featureAccessInsights?.canViewActivity === true;
};

export const useIsInsightsLeadersOrgPageEnabled: UseUIFeatureCheck<[], boolean | undefined> = () => {
  const insightsLeadersPrerequisites = useInsightsLeadersPrerequisites();
  const featureAccessInsights = useFeatureAccessInsights();

  return insightsLeadersPrerequisites && featureAccessInsights?.canViewLeadershipActivity === true;
};

export const useIsVivaInsightsNetworkEnabled: UseUIFeatureCheck<[], boolean | undefined> = () => {
  const featureAccessInsights = useFeatureAccessInsights();
  const insightsLeadersPrerequisites = useInsightsLeadersPrerequisites();
  const canViewNetworkActivity = featureAccessInsights?.canViewNetworkActivity === true;
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const isLeaderSelectedOnBehalfOfViewer = !!selectedOnBehalfOfUserId;

  return insightsLeadersPrerequisites && canViewNetworkActivity && isLeaderSelectedOnBehalfOfViewer === false;
};

export const useIsVivaInsightsAudienceThemeCardEnabled: UseUIFeatureCheck = () =>
  useExperimentTreatmentWithoutReporting('VivaInsightsAudienceThemeCard') === true;

export const useIsCommunityInsightsV2Enabled: UseUIFeatureCheck = () =>
  useExperimentTreatmentWithoutReporting('CommunityInsightsV2') === true;

export const useIsPremiumCommunityInsightsV2Enabled: UseUIFeatureCheck<[], boolean | undefined> = () => {
  const featureAccessInsights = useFeatureAccessInsights();
  const isCommunityInsightsV2Enabled = useExperimentTreatmentWithoutReporting('CommunityInsightsV2') === true;
  const insightsLeadersPrerequisites = useInsightsLeadersPrerequisites();
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const isNotLeaderSelectedOnBehalfOfViewer = !!selectedOnBehalfOfUserId === false;
  const canViewActivity = featureAccessInsights?.canViewActivity === true;

  return (
    insightsLeadersPrerequisites &&
    isCommunityInsightsV2Enabled &&
    isNotLeaderSelectedOnBehalfOfViewer &&
    canViewActivity
  );
};
