import { getOcpsPolicies } from '@ms/yammer-data/dist/domains/ocps/selectors';
import { ComplianceCheckOption, ComplianceChecks } from '@ms/yammer-data/dist/domains/ocv/types';
import { OcpsPolicy, YammerState } from '@ms/yammer-data/dist/state/types';
import { useMemo } from 'react';

import { useSelector } from '../useSelector';

export const getComplianceValue = (value: boolean | null): ComplianceCheckOption => {
  if (value == null) {
    return ComplianceCheckOption.NotConfigured;
  }

  return value ? ComplianceCheckOption.Enabled : ComplianceCheckOption.Disabled;
};

export const useComplianceChecks = (): ComplianceChecks => {
  const policies = useSelector((state: YammerState) => getOcpsPolicies(state));

  return useMemo(() => {
    let policyAllowFeedback = ComplianceCheckOption.NotConfigured;
    let policyAllowSurvey = ComplianceCheckOption.NotConfigured;
    let connectedExperiences = null;
    let policyAllowScreenshot = ComplianceCheckOption.NotConfigured;
    let policyAllowContact = ComplianceCheckOption.NotConfigured;
    let policyAllowContent = ComplianceCheckOption.NotConfigured;
    if (policies) {
      policyAllowFeedback = getComplianceValue(policies[OcpsPolicy.UserFeedbackFormEnabled]);
      policyAllowSurvey = getComplianceValue(policies[OcpsPolicy.SurveyFeedbackFormEnabled]);
      if (policies[OcpsPolicy.ConnectedExperiencesEnabled]) {
        connectedExperiences = getComplianceValue(policies[OcpsPolicy.ConnectedExperiencesEnabled]) as
          | ComplianceCheckOption.Enabled
          | ComplianceCheckOption.Disabled;
      }
      policyAllowScreenshot = getComplianceValue(policies[OcpsPolicy.AllowScreenshots]);
      policyAllowContact = getComplianceValue(policies[OcpsPolicy.AllowEmailCollection]);
      policyAllowContent = getComplianceValue(policies[OcpsPolicy.AllowLogCollection]);
    }

    return {
      authenticationType: 'AAD',
      ageGroup: 'Undefined',
      connectedExperiences,
      policyAllowFeedback,
      policyAllowSurvey,
      policyAllowScreenshot,
      policyAllowContact,
      policyAllowContent,
    };
  }, [policies]);
};
