// eslint-disable-next-line no-restricted-syntax
export enum SearchResultType {
  All = 'All',
  Thread = 'Thread',
  Group = 'Group',
  User = 'User',
  File = 'File',
  Inbox = 'Inbox',
  Topic = 'Topic',
  NetworkQuestion = 'NetworkQuestion',
  Campaign = 'Campaign',
}

export type PrimitiveSearchResultType = Exclude<SearchResultType, SearchResultType.All>;

export interface SearchLocationParameters {
  readonly searchText?: string;
  readonly resultType?: SearchResultType;
  readonly groupScope?: string;
  readonly campaignId?: string;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly networkId?: string;
  readonly participantUserIds?: string[];
  readonly threadStarterSenderId?: string;
  readonly postType?: string;
}
